import { render, staticRenderFns } from "./spinning-brightbid-logo.vue?vue&type=template&id=18c206b7&scoped=true"
import script from "./spinning-brightbid-logo.vue?vue&type=script&lang=js"
export * from "./spinning-brightbid-logo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c206b7",
  null
  
)

export default component.exports